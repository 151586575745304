<template>
  <v-card-text class="pa-1">
    <v-container class="pa-2" fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-card-title class="pa-2">
              <v-row dense>
                <v-col cols="12" sm="12" md="2">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="datei"
                        label="Selecionar fecha"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        clearable
                        v-on="on"
                        @click:clear="
                          datei = null;
                          setDatesClear();
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      range
                      v-model="datei"
                      @change="
                        menu = false;
                        setDates();
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer />
                <v-btn
                  dense
                  color="#479b25"
                  dark
                  class="ma-3"
                  @click="exportar()"
                >
                  Exportar Reporte
                </v-btn>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-row align="center" class="list px-3 mx-auto">
                <v-col cols="12" sm="12">
                  <v-data-table
                    :mobile-breakpoint="0"
                    class="iconTable"
                    :headers="headers"
                    :items="report_guests.rows"
                    disable-pagination
                    :hide-default-footer="true"
                    :loading="fetchingData"
                    :loading-text="'Cargando... Espere por favor'"
                    :no-data-text="'datos no disponibles'"
                    item-key="_id"
                    hide-default-header
                  >
                    <template #header="{ props: { headers } }">
                      <thead class="v-data-table-header">
                        <tr>
                          <template v-for="header in headers">
                            <th :key="header.value" :class="header.align">
                              <v-row>
                                <v-col cols="12">
                                  <h2 style="color: #000">
                                    {{ header.text }}
                                    <v-icon
                                      v-if="header.text !== 'Detalle'"
                                      color="primary"
                                    >
                                      mdi-arrow-down-drop-circle
                                    </v-icon>
                                    <v-icon v-else />
                                  </h2>
                                </v-col>
                              </v-row>
                            </th>
                          </template>
                        </tr>
                      </thead>
                    </template>
                    <template #[`item.createdAt`]="{ item }">
                      <td
                        :style="
                          report_guests.rows.indexOf(item) % 2 == 0
                            ? 'background:#eeee!important'
                            : ''
                        "
                      >
                        {{ getDateFormat(item.createdAt) }}
                      </td>
                    </template>
                    <template #[`item.names`]="{ item }">
                      <td
                        :style="
                          report_guests.rows.indexOf(item) % 2 == 0
                            ? 'background:#eeee!important'
                            : ''
                        "
                      >
                        {{ item.names }}
                      </td>
                    </template>
                    <template #[`item.last_name`]="{ item }">
                      <td
                        :style="
                          report_guests.rows.indexOf(item) % 2 == 0
                            ? 'background:#eeee!important'
                            : ''
                        "
                      >
                        {{ item.last_name }}
                      </td>
                    </template>
                    <template #[`item.document_number`]="{ item }">
                      <td
                        :style="
                          report_guests.rows.indexOf(item) % 2 == 0
                            ? 'background:#eeee!important'
                            : ''
                        "
                      >
                        {{ item.document_number }}
                      </td>
                    </template>
                    <template
                      #[`item.membership_guest[0].invited.fullname`]="{ item }"
                    >
                      <td
                        :style="
                          report_guests.rows.indexOf(item) % 2 == 0
                            ? 'background:#eeee!important'
                            : ''
                        "
                      >
                        {{ item.membership_guest[0].invited.fullname }}
                      </td>
                    </template>
                    <template #[`item.role`]="{ item }">
                      <td
                        :style="
                          report_guests.rows.indexOf(item) % 2 == 0
                            ? 'background:#eeee!important'
                            : ''
                        "
                      >
                        {{ item.role }}
                      </td>
                    </template>
                  </v-data-table>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-row>
                    <v-col cols="4" sm="3">
                      <v-select
                        v-model="pageSize"
                        :items="pageSizes"
                        label="Items per Page"
                        @change="handlePageSizeChange"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="9">
                      <v-pagination
                        v-model="page"
                        :length="report_guests.totalPages"
                        total-visible="7"
                        next-icon="mdi-menu-right"
                        prev-icon="mdi-menu-left"
                        @input="handlePageChange"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "table-report-guest",
  params: ["range_dates"],
  watch: {
    text: function (value) {
      this.getFetch();
    },
  },
  computed: {
    ...mapState({
      report_guests: (state) => state.report.report_guests,
      fetchingData: (state) => state.fetchingData,
    }),
    text() {
      return this.$route.query.text;
    },
  },
  methods: {
    ...mapActions(["exportGuests", "getGuests"]),
    exportar() {
      this.exportGuests({ params: this.getRequestParams() });
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (this.text) {
        params["text"] = this.text;
      }

      if (this.datei) {
        if (this.datei[0] && this.datei[1]) {
          params["start_date"] = this.datei[0];
          params["end_date"] = this.datei[1];
        }
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.getFetch();
      //this.retrieveTutorials();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getFetch();
      //this.retrieveTutorials();
    },
    getFetch() {
      const params = this.getRequestParams(this.page, this.pageSize);
      this.getGuests({ params: params });
    },
    setDates() {
      this.getFetch();
    },
    setDatesClear() {
      this.getFetch();
    },
  },
  data: () => ({
    headers: [
      { text: "Fecha y Hora", value: "createdAt", width: 150 },
      { text: "Nombre", value: "names", width: 200 },
      { text: "Apellido", value: "last_name" },
      { text: "Documento", value: "document_number" },
      { text: "Invitado por", value: "membership_guest[0].invited.fullname" },
      { text: "Estado", value: "role" },
    ],
    page: 1,
    totalPages: 0,
    pageSizes: [10, 20, 30],
    pageSize: 10,
    datei: null,
    menu: null,
  }),
  created() {
    //console.log(this.date);
    this.getFetch();
  },
};
</script>

<style>
</style>